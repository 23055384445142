import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import bg from "../images/staticsvgback.svg"
import { useTranslation } from "react-i18next"
import ContactForm from "../components/contactform"

const Contact = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <Seo title={t("Contact")} />
      <div
        className="py-5 bg-light gap-5 d-flex flex-column
    justify-content-center align-items-center"
        style={{
          background: `url(${bg}) no-repeat fixed center`,
          backgroundSize: "contain",
          minHeight: "75vh",
        }}
      >
        <ContactForm page="uhappy? - Contact" landing={false} />
      </div>
    </Layout>
  )
}
export default Contact

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
